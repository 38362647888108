export const redirectUrlByLocation = (categories) => {
  let redirectUrl = "";

  redirectUrl = "/options/locations";

  return redirectUrl;
};

export const redirectUrlByType = (categories) => {
  let redirectUrl = "";

  // If more than one category, redirect to category choice screen
  if (Object.keys(categories).length > 1) {
    redirectUrl = "/options/categories?";
  }

  // If exactly one category, save the category in a variable
  else if (Object.keys(categories).length === 1) {
    const category = Object.keys(categories)[0];

    if (categories[category]?.has_types) {
      // If category has only one type, redirect to this category / type combination
      if (categories[category].types.length === 1) {
        redirectUrl = `/${category}?type=${categories[category].types[0]}&`;
      }
      // If category has multiple types, redirect to type choice screen
      else {
        redirectUrl = `/options/types?category=${category}&`;
      }
    } // If category has no choice types, redirect to category page
    else {
      redirectUrl = `/${category}`;
    }
  }

  return redirectUrl;
};

export const redirectUrlByCategory = (category) => {
  let redirectUrl = "";

  // If more than one category, redirect to category choice screen
  if (category.types.length > 1) {
    redirectUrl = `/options/types?category=${category.category}`;
  }

  // If exactly one category, save the category in a variable
  else if (category.types.length === 1) {
    redirectUrl = `/${category.category}?type=${category.types[0]}`;
  }

  return redirectUrl;
};

export const redirectIfNotLoggedIn = async (req) => {
  const domainConfig = await req.session.get("domainConfig");
  const token = domainConfig?.token;

  if (!token && req.url != "/") {
    return {
      redirect: {
        destination: "/",
        permanent: false,
      },
    };
  } else return null;
};
